import React from "react";
import "../css/contact.css";
import logo from "../img/gis_logo.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Contact = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="contact-container">
        <div className="contact-container__column-1">
          <img src={logo} alt="" />
          <div className="contact-container__reference">
            <Link to={"/cookies-policy"}>{t("Πολιτική")}</Link>|
            <Link to={"/terms-of-use"}>{t("Όροι Χρήσης")}</Link>|
            <Link to={"/privacy"}>{t("Προσωπικά δεδομένα")}</Link>
          </div>
        </div>
        <div className="contact-container__column-2">
          <div className="contact-container__details">
            <h4>{t("Επικοινωνία")}</h4>
            <p>
              {t("Διεύθυνση")}:{" "}
              <span>{t("Ναυάρχου Κουντουριώτου 3, Θεσσαλονίκη 546 25")}</span>
            </p>
            <p>
              Email: <span>info@dotsoft.gr</span>
            </p>
            <p>
              {t("Τηλέφωνο")}: <span>+30 2310 500181</span>
            </p>
            <p>
              Fax: <span>+30 2310 551844</span>
            </p>
          </div>
        </div>
      </div>
      <div className="contact-container__copyrights">
        <p>© {new Date().getFullYear()} smartiscity. All Rights Reserved.</p>
      </div>
    </div>
  );
};

export default Contact;
