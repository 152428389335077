import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Main from "./main";
import Zitsa from "./components/zitsa";
import Privacy from "./components/Privacy";
import TermsOfUse from "./components/TermsOfUse";
import CookiesPolicy from "./components/CookiesPolicy";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms-of-use" element={<TermsOfUse />} />
        <Route path="/cookies-policy" element={<CookiesPolicy />} />
        <Route path="/dimos-zitsas" element={<Zitsa />}></Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
