import React from "react";
import { useTranslation } from "react-i18next";

const cardArray = [
  {
    title: "Καρτέλα μορφοποίησης θεματικών επιπέδων",
    imageUrl: "/layers-active.svg",
  },
  {
    title: "Εναλλαγή χαρτογραφικών υποβάθρων",
    imageUrl: "/basemap-active.svg",
  },
  {
    title: "Χωρικά ερωτήματα και εργαλεία χωρικής ανάλυσης",
    imageUrl: "/spatial-active.svg",
  },
  {
    title: "Λήψη/εξαγωγή δεδομένων",
    imageUrl: "/download-active.svg",
  },
  {
    title: "Εργαλεία πλοήγησης",
    imageUrl: "/navigation-active.svg",
  },
  {
    title: "Αναζήτηση διεύθυνσης",
    imageUrl: "/search-active.svg",
  },
  {
    title: "Δυνατότητα Μετρήσεων",
    imageUrl: "/measurement-active.svg",
  },
  {
    title: "Εισαγωγή αρχείων",
    imageUrl: "/import-active.svg",
  },
  {
    title: "Μορφοποίηση θεματικών επιπέδων",
    imageUrl: "/edit-active.svg",
  },
  {
    title: "Εργαλεία αναγνώρισης",
    imageUrl: "/info-active.svg",
  },
  {
    title: "Δημιουργία γραφικών στοιχείων (Widgets)",
    imageUrl: "/graph-active.svg",
  },
  {
    title: "Δυνατότητα εκτύπωσης",
    imageUrl: "/print-active.svg",
  },
  {
    title: "Εισαγωγή δεδομένων απο ελεύθερες χαρτογραφικές υπηρεσίες",
    imageUrl: "/noname-active.svg",
  },
  {
    title: "Δημιουργία Γεω-ιστοριών (story maps)",
    imageUrl: "/geostory-active.svg",
  },
  {
    title: "Δημιουργία Διαδρομής",
    imageUrl: "/route-active-01.svg",
  },
];
const Functionality = () => {
  const { t } = useTranslation();
  return (
    <div className="wrapper">
      {cardArray.map((item, index) => (
        <div key={index} className="wrapper-content">
          <img src={item.imageUrl} alt="" />
          <h4>{t(item.title)}</h4>
        </div>
      ))}
    </div>
  );
};

export default Functionality;
