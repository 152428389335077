import "./../css/RecommendedCard.css";

const RecommendedCard = ({ img, title, link }) => {
  return (
    <div
      className={"recommended__card"}
      onClick={() => link && window.open(link, "rel=noreferrer")}
    >
      <img src={img} alt={"img "} />
      <div className={"recommended__card__content"}>
        <h4>{title}</h4>
      </div>
    </div>
  );
};
export default RecommendedCard;
