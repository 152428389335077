import "./../css/Recommended.css";
import RecommendedCard from "./RecommendedCard";
// assets
import lamiaImage from "./../img/lamia.png";
import tourismImage from "./../img/tourism.png";
import universityOfIoannina from "./../img/university_of_ioannina.jpg";

const pogoniImage =
  "https://www.smartiscity.gr/wp-content/uploads/2021/11/dimos-pogoniou.jpg";
const zitsaImage =
  "https://www.smartiscity.gr/wp-content/uploads/2021/11/dimos-zitsas.jpg";

const line5 = [
  {
    img: lamiaImage,
    title: "Δήμος Λαμίας",
    link: "http://gis.lamia.gr/",
  },
  {
    img: tourismImage,
    title: "Υπουργείο Τουρισμού",
    link: "https://innovmedicaltourism.app/",
  },
  {
    img: zitsaImage,
    title: "Δήμος Ζίτσας",
    link: "https://zitsa.gis4smart.city/",
  },
  {
    img: pogoniImage,
    title: "Δήμος Πωγωνίου",
    link: "https://pogoni.gis4smart.city/",
  },
  {
    img: universityOfIoannina,
    title: "Πανεπιστήμιο Ιωαννίνων",
    link: "http://5.172.198.45:8080/mapstore/#/viewer/openlayers/42",
  },
];
const Recommended = ({ title }) => {
  return (
    <div className={"recommended__wrapper functionality-content"}>
      <h2 style={{ marginBottom: "40px" }}>{title}</h2>
      <div className={"recommended__lines_4or5"}>
        {line5.map((item, index) => (
          <RecommendedCard
            key={index}
            img={item.img}
            title={item.title}
            link={item.link}
          />
        ))}
      </div>
    </div>
  );
};

export default Recommended;
