import React from "react";
import "../css/clients.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

//Import img
import lamia from "../img/dimos-lamieon-logo-e1611908863148-removebg-preview.png";
import zitsa from "../img/zitsa.png";
import ministry_tourism from "../img/ministry_tourism-remove.png";
import aigaleo from "../img/aigaleo-remove.png";
import uni from "../img/university-of-ioannina-logo-remove.png";
import pindos from "../img/pindos-remove.png";
import sidly from "../img/iwannina-dimos-logo.png";
import pogoni from "../img/LOGO_ΔΗΜΟΥ_ΠΩΓΩΝΙΟΥ-removebg-preview.png";
import { useTranslation } from "react-i18next";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
};

const Clients = () => {
  const { t } = useTranslation();
  return (
    <div className="clients-container">
      <h2>{t("Πελάτες")}</h2>
      <Carousel
        swipeable={true}
        draggable={true}
        showDots={true}
        responsive={responsive}
        autoPlaySpeed={3500}
        autoPlay={true}
        infinite={true}
      >
        <img src={lamia} alt="" />
        <img src={zitsa} alt="" />
        <img src={ministry_tourism} alt="" />
        <img src={aigaleo} alt="" />
        <img src={uni} alt="" />
        <img src={pindos} alt="" />
        <img src={sidly} alt="" />
        <img src={pogoni} alt="" />
      </Carousel>
    </div>
  );
};
export default Clients;
