import "./../css/Privacy.css";

const appName = "gis4smart.city";
const CookiesPolicy = () => {
  return (
    <div className={"privacy__overlay"}>
      <div>
        <p>
          Για την καλύτερη λειτουργία του διαδικτυακού τόπου {appName}.gr και τη
          βελτίωση της πλοήγησής σας σε αυτόν, χρησιμοποιούμε cookies. Μάθετε τι
          είναι τα cookies, ποια cookies χρησιμοποιούμε και πώς μπορείτε να
          απενεργοποιήσετε τη χρήση τους.
        </p>

        <h1>ΤΙ ΕΙΝΑΙ ΤΑ COOKIES</h1>

        <p>
          Τα cookies είναι μικρά αρχεία κειμένου τα οποία αποθηκεύει στον
          υπολογιστή σας, το κινητό σας ή άλλη ηλεκτρονική συσκευή σας ένας
          διαδικτυακός τόπος όταν τον επισκέπτεστε.
        </p>

        <p>
          Με τα cookies ο διαδικτυακός τόπος θυμάται για κάποιο χρονικό διάστημα
          τις ενέργειες και τις προτιμήσεις σας, όπως γλώσσα, μέγεθος
          γραμματοσειράς και άλλες προτιμήσεις απεικόνισης. Έτσι δεν χρειάζεται
          να καταχωρίζετε τις προτιμήσεις αυτές κάθε φορά που επισκέπτεστε τον
          διαδικτυακό τόπο ή πλοηγείστε στις σελίδες του.
        </p>

        <p>
          Επιπλέον, τα cookies μάς βοηθούν να αναλύουμε πώς χρησιμοποιείτε τον
          διαδικτυακό τόπο μας και εάν αντιμετωπίζετε προβλήματα κατά την
          περιήγησή σας.
        </p>

        <p>
          Περισσότερες πληροφορίες σχετικά με τα cookies μπορείτε να βρείτε στον
          διαδικτυακό τόπο www.allaboutcookies.org.
        </p>

        <h1>ΠΟΙΑ COOKIES ΧΡΗΣΙΜΟΠΟΙΕΙ ΤΟ {appName}.GR</h1>

        <p>
          Στον διαδικτυακό τόπο μας χρησιμοποιούμε βασικά cookies, cookies
          επιδόσεων, λειτουργικότητας και στόχευσης/διαφήμισης, καθώς επίσης και
          διαχείρισης κίνησης. Τα cookies που χρησιμοποιούμε είναι είτε δικά μας
          είτε έμπιστων παρόχων.
        </p>

        <h2>Χρησιμοποιούμε δύο κατηγορίες cookies:</h2>

        <ul>
          <li>
            Session cookies (προσωρινά cookies) – Παραμένουν στη συσκευή σας
            μέχρι να εγκαταλείψετε τον διαδικτυακό τόπο μας.
          </li>

          <li>
            Persistent cookies (μόνιμα cookies) – Παραμένουν στη συσκευή σας για
            μεγαλύτερο χρονικό διάστημα ή μέχρι να τα διαγράψετε εσείς. Η
            διάρκεια παραμονής τους στη συσκευή σας εξαρτάται από τη διάρκεια
            ζωής του συγκεκριμένου cookie και τις ρυθμίσεις του browser σας.
          </li>
        </ul>

        <p>
          Τα cookies αποθηκεύονται στη συσκευή σας μόνον εφόσον δώσετε τη
          συγκατάθεσή σας. Εάν δεν αποδεχθείτε τα cookies, μπορεί να επηρεαστεί
          η απόδοση και η λειτουργικότητα του διαδικτυακού τόπου.
        </p>

        <h1>ΒΑΣΙΚΑ COOKIES</h1>

        <p>
          Αυτά τα cookies είναι απαραίτητα για τη σωστή λειτουργία του
          διαδικτυακού τόπου μας. Σας επιτρέπουν να κάνετε περιήγηση σε αυτόν
          και να χρησιμοποιείτε τις λειτουργίες του, όπως πρόσβαση σε ασφαλείς
          περιοχές.
        </p>

        <h1>COOKIES ΕΠΙΔΟΣΕΩΝ</h1>

        <p>
          Τα cookies επιδόσεων συλλέγουν πληροφορίες σχετικά με τον τρόπο που
          χρησιμοποιείτε τον διαδικτυακό τόπο, όπως ποιες σελίδες επισκέπτεστε
          συχνότερα και εάν λαμβάνετε μηνύματα σφαλμάτων. Οι πληροφορίες που
          συλλέγουν είναι συγκεντρωτικές. Τα χρησιμοποιούμε μόνο για να
          βελτιώσουμε τις επιδόσεις του διαδικτυακού τόπου μας.
        </p>

        <h1>COOKIES ΛΕΙΤΟΥΡΓΙΚΟΤΗΤΑΣ</h1>

        <p>
          Τα cookies λειτουργικότητας βοηθούν τον διαδικτυακό τόπο να θυμάται
          τις επιλογές σας, όπως τη γλώσσα και την περιοχή σας. Έτσι, σας
          παρέχουμε βελτιωμένες και προσωποποιημένες λειτουργίες. Επιπλέον,
          βοηθούν στην παροχή υπηρεσιών που έχετε ζητήσει εσείς όπως προβολή
          βίντεο ή χρήση μέσων κοινωνικής δικτύωσης. Δεν μας δίνουν τη
          δυνατότητα να παρακολουθήσουμε τη δραστηριότητά σας σε άλλους
          διαδικτυακούς τόπους.
        </p>

        <h1>COOKIES ΣΤΟΧΕΥΣΗΣ /ΔΙΑΦΗΜΙΣΗΣ ΚΑΙ ΣΤΑΤΙΣΤΙΚΩΝ ΧΡΗΣΗΣ</h1>

        <p>
          Χρησιμοποιούμε αυτά τα cookies για να σας προβάλλουμε διαφημίσεις και
          για να σας στέλνουμε μηνύματα προώθησης που ταιριάζουν περισσότερο σε
          εσάς και τα ενδιαφέροντά σας. Μπορεί να τα χρησιμοποιήσουμε για αυτούς
          τους σκοπούς:
        </p>

        <ul>
          <li>Αποστολή στοχευμένης διαφήμισης/προσφορών.</li>
          <li>Περιορισμός διαφημιστικών προβολών.</li>
          <li>Μέτρηση αποτελεσματικότητας μιας διαφημιστικής καμπάνιας.</li>
          <li>
            Προσδιορισμός αποτελεσματικότητας των ηλεκτρονικών καναλιών
            μάρκετινγκ και επιβράβευση εξωτερικών ιστοσελίδων και συνεργατών που
            σας προώθησαν σε εμάς.
          </li>
        </ul>

        <p>
          Επίσης, για να βελτιώσουμε τον διαδικτυακό τόπο μας, χρησιμοποιούμε
          cookies για να συλλέξουμε πληροφορίες σχετικά με τον χρόνο και τον
          τρόπο που οι επισκέπτες αλληλεπιδρούν με τον διαδικτυακό τόπο μας, τις
          διαφημίσεις και τις υπηρεσίες που προσφέρει. Για παράδειγμα, πόσες
          φορές είδε ο επισκέπτης ένα συγκεκριμένο προϊόν ή υπηρεσία και εάν
          επέλεξε να το κοινοποιήσει σε κάποια πλατφόρμα κοινωνικής δικτύωσης.
        </p>

        <h1>ΔΙΑΧΕΙΡΙΣΗ ΚΙΝΗΣΗΣ</h1>

        <p>
          Στον διαδικτυακό τόπο μας χρησιμοποιούμε cookies διαχείρισης της
          κίνησης για να εντοπίζουμε τεχνικά προβλήματα που μπορεί να
          εμφανιστούν κατά την περιήγησή σας. Με βάση τις πληροφορίες που
          συγκεντρώνουν τα cookies αυτά , διορθώνουμε τεχνικά λάθη και
          προβλήματα, και βελτιώνουμε διαρκώς τις υπηρεσίες που προσφέρουμε μέσα
          από τον διαδικτυακό τόπο μας. Έτσι, ανταποκρινόμαστε καλύτερα στις
          προσωπικές ανάγκες σας.
        </p>

        <h1>COOKIES ΑΛΛΩΝ ΥΠΗΡΕΣΙΩΝ ( THIRD – PARTY COOKIES )</h1>

        <p>
          Ορισμένοι έμπιστοι συνεργάτες μας επίσης τοποθετούν cookies στη
          συσκευή σας για λογαριασμό μας όταν επισκέπτεστε τον διαδικτυακό μας
          τόπο. Με αυτά τα cookies συλλέγουν δεδομένα για την απόδοση των
          διαφημίσεων και την αλληλεπίδρασή σας με τον διαδικτυακό τόπο μας.
          Μπορεί να χρησιμοποιήσουν αυτά τα δεδομένα για να εξάγουν στατιστικά
          συμπεράσματα και να βελτιώσουν τη διαφημιστική εμπειρία σας ως
          επισκέπτη.
        </p>

        <h1>ΠΩΣ ΜΠΟΡΕΙΤΕ ΝΑ ΔΙΑΓΡΑΨΕΤΕ ΤΑ COOKIES</h1>
        <p>
          Μπορείτε να διαγράψετε τα cookies και να απενεργοποιήσετε τη χρήση
          τους. Επιλέξτε τον browser που χρησιμοποιείτε και ακολουθήστε τις
          οδηγίες:
        </p>

        <ul>
          <li>Chrome</li>
          <li>Safari</li>
          <li>Firefox</li>
          <li>Internet Explorer</li>
        </ul>

        <p>
          Εάν χρησιμοποιείτε κάποιον άλλον browser, συμβουλευτείτε τις
          πληροφορίες του παρόχου του. Η παρούσα Πολιτική cookies συμπληρώνεται
          από την ενημέρωση για τα προσωπικά δεδομένα σας.
        </p>
      </div>
    </div>
  );
};

export default CookiesPolicy;
