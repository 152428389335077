import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../css/carousel.css";

//Import Images
import ypodomes from "../img/Dotspartial Vectors-01.png";
import perivallon from "../img/Dotspartial Vectors-02.png";
import poleodomia from "../img/Dotspartial Vectors-03.png";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const Content = () => {
  return (
    <div className="content-carousel">
      <Carousel
        swipeable={false}
        draggable={false}
        showDots={true}
        responsive={responsive}
        autoPlaySpeed={5000}
        autoPlay={true}
        infinite={true}
      >
        <img src={ypodomes} alt="" />
        <img src={perivallon} alt="" />
        <img src={poleodomia} alt="" />
      </Carousel>
    </div>
  );
};
export default Content;
