import React from "react";

import Header from "./components/header";
import Content from "../src/components/content";
import Wrapper from "./components/wrapper";
import Description from "./components/description";
import Contact from "../src/components/contact";
import Clients from "./components/clients";
import BottomUp from "./components/bottomUp";
import Recommended from "./components/Recommended";

const recommendedProjects = "Ενδεικτικά Έργα";
const Main = () => {
  return (
    <div className="content-container">
      <Header />
      <Content />
      <Wrapper />
      <Description />
      <Clients />
      <Recommended title={recommendedProjects} />
      <Contact />
      <BottomUp />
    </div>
  );
};

export default Main;
