import React from "react";
import "../css/contact_page.css";
import logo from "../img/gis_logo.png";

class Contact extends React.Component {
  render() {
    return (
      <div>
        <div className="contact-container">
          <div className="contact-container__column-1">
            <img src={logo} alt="" />
            <div className="contact-container__reference">
              {/* eslint-disable-next-line react/jsx-no-target-blank */}
              <a
                href="https://site.smartiscity.gr/cookies-policy/"
                target="_blank"
              >
                Πολιτική
              </a>
              |{/* eslint-disable-next-line react/jsx-no-target-blank */}
              <a
                href="https://site.smartiscity.gr/cookies-policy/"
                target="_blank"
              >
                Όροι Χρήσης
              </a>
              |{/* eslint-disable-next-line react/jsx-no-target-blank */}
              <a
                href="https://site.smartiscity.gr/cookies-policy/"
                target="_blank"
              >
                Προσωπικά δεδομένα
              </a>
            </div>
          </div>
          <div className="contact-container__column-2">
            <div className="contact-container__details">
              <h4>Επικοινωνία</h4>
              <p>
                Διεύθυνση:{" "}
                <span>Ναυάρχου Κουντουριώτου 3, Θεσσαλονίκη 546 25</span>
              </p>
              <p>
                Email: <span>info@dotsoft.gr</span>
              </p>
              <p>
                Τηλέφωνο: <span>+30 2310 500181</span>
              </p>
              <p>
                Fax: <span>+30 2310 551844</span>
              </p>
            </div>
          </div>
        </div>
        <div className="contact-container__copyrights">
          <p>© {new Date().getFullYear()} smartiscity. All Rights Reserved.</p>
        </div>
      </div>
    );
  }
}

export default Contact;
