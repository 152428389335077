import React from "react";
import "../css/description.css";
import { useTranslation } from "react-i18next";

const Description = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="functionality-description-1">
        <div className="functionality-description-1__column-1"></div>
        <div className="functionality-description-1__column-2">
          <h2>
            {t(
              "DotSpatial - Λογισμικό δημιουργίας υποδομής γεωχωρικών δεδομένων"
            )}
          </h2>
          <p>
            {t(
              "Το DotSpatial αποτελεί μια σύγχρονη λύση για την δημιουργία διαδικτυακών υποδομών γεωχωρικών πληροφοριών, που συνδυάζει προηγμένες λειτουργίες συστημάτων γεωγραφικών πληροφοριών (GIS), σε συνδυασμό με το σύνολο των πλεονεκτημάτων που προσφέρουν τα δεδομένα ανοικτού κώδικα, σε ένα υψηλής αισθητικής και λειτουργικότητας γραφικό περιβάλλον."
            )}
            <br />
            <br />
            {t(
              "Eίναι κάτι περισσότερο απο ένα απλό λογισμικό θέασης και επεξεργασίας γεωχωρικών δεδομένων. Συνιστά περισσότερο μια υποδομή γεωχωρικών δεδομένων (Spatial Data Infrastructure), το οποίο λειτουργώντας κάτω από συγκεκριμένους κανόνες και πρωτόκολλα επιτρέπει τη διαχείριση των γεωγραφικών πληροφοριών (χάρτες, ορθοφωτογραφίες, δορυφορικές εικόνες κ.α.), υπερπηδώντας οποιοδήποτε γεωγραφικό περιορισμό."
            )}
          </p>
        </div>
      </div>
      <div className="functionality-description-2">
        <div className="functionality-description-2__column-1">
          <h2>{t("Αρχιτεκτονική του DotSpatial")}</h2>
          <p>
            {t(
              "Η αρχιτεκτονική που έχει επιλεγεί για την δημιουργία της προσφερόμενης λύσης βασίζεται σε τέσσερα (4) επίπεδα:"
            )}
            <li>{t("Επίπεδο βάσεων δεδομένων")}</li>
            {t(
              "Το επίπεδο αυτό υποστηρίζεται από την PostgreSQL μαζί με την χωρική επέκταση PostGIS, που αποτελεί το πιο προηγμένο σύστημα ανοικτού κώδικα της κατηγορίας."
            )}
            <li>{t("Επίπεδο υποστηρικτικών εφαρμογών γραφείου")}</li>
            {t(
              "Στο επίπεδο αυτό ανήκει το QGIS, το οποίο και αποτελεί λογισμικό Συστημάτων Γεωχωρικών Πληροφοριών ανοιχτού κώδικα. Το QGIS αποτελεί ολοκληρωμένο πακέτο μέσω του οποίου μπορούν να εκτελεσθούν όλες οι βασικές λειτουργίες διαχείρισης, ανάλυσης, επεξεργασίας και παρουσίασης γεωχωρικών δεδομένων."
            )}
            <li>{t("Επίπεδο εφαρμογών")}</li>
            {t(
              "Το λογισμικό GeoServer αποτελεί διαδικτυακό εξυπηρετητή χαρτών (map server) ανοικτού κώδικα που επιτρέπει τη δημοσίευση γεωχωρικών δεδομένων σε λογισμικά-πελάτες (clients), όπως οι περιηγητές διαδικτύου (Web browsers) και τα λογισμικά GIS."
            )}
            <li>{t("Επίπεδο διαδικτύου")}</li>
          </p>
        </div>
        <div className="functionality-description-2__column-2"></div>
      </div>
      <div
        className="functionality-description-3"
        id="functionality-description-3"
      >
        <div className="functionality-description-3__column-1"></div>
        <div className="functionality-description-3__column-2">
          <h2>{t("Πλεονεκτήματα - Δυνατότητες του DotSpatial")}</h2>
          <p>
            {t(
              "Η προσφερόμενη λύση αποτελεί ιδανική επιλογή για την δημιουργία μιας σύγχρονης και επεκτάσιμης υποδομής γεωχωρικών δεδομένων. Μερικά απο τα πλεονεκτήματα είναι τα εξής:"
            )}
            <li>{t("Απεριόριστος αριθμών αδειών και χρηστών")}</li>
            <li>
              {t(
                "Κόστος. Το σύνολο της αρχιτεκτονικής της υποδομής γεωχωρικών δεδομένων βασίζεται αποκλειστικά σε λογισμικά ανοιχτού κώδικα, γεγονός που έχει σημαντική επίδραση στο κόστος της προσφερόμενης λύσης ειδικά συγκριτικά με αντίστοιχα εμπορικά πακέτα (π.χ. ArcGIS Enterprise)"
              )}
            </li>
            <li>
              {t(
                "Επεκτασιμότητα. Δυνατότητα επέκτασης των προσφερόμενων υπηρεσιών και λειτουργιών της υποδομής γεωχωρικών δεδομένων"
              )}
            </li>
            <li>{t("Υψηλής αισθητικής γραφικό περιβάλλον")}</li>
            <li>{t("Δυνατότητα διασύνδεσης με τρίτες εφαρμογές")}</li>
          </p>
        </div>
      </div>
    </div>
  );
};
export default Description;
